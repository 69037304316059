<template>
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return">
                    <el-button type="text" size="small" icon="el-icon-arrow-left" @click="back">返回</el-button>
                </p>
            </div>
        </div>
        <el-tabs type="border-card" v-model.trim="activeName" class="tab-container">
            <el-tab-pane label="订单详情" name="tabbasic">
                <el-collapse v-model.trim="collapseItem">
                    <el-collapse-item title="基本信息" name="basicinfo">
                        <el-form ref="soForm" :model="dataSource">
                            <el-form-item>
                                <el-col :span="2" class="form-title">调度单号：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <span>{{dataSource.OrderNo}}</span>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">单据创建时间：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.OrderTime}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">单据创建方式：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <span>{{dataSource.DisplayOrderCreateType}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">调度状态：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.DisplayOrderStatus}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">关联调度单号：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.RelatedOrderNo}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">标准物流订单号：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.StandardOrderNo}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">原单号：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.SourceOrderNo}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">调度订单类型：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.DisplayOrderType}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">仓配调度类型：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.DisplayOrderWtType}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">订单类型：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.DisplayOrderOperationType}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">货主编码：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.CustomerCode}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">货主名称：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.CustomerName}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">客户编码：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.ClientCode}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">客户名称：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.ClientName}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">供应商编码：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.SupplierCode}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">供应商名称：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.SupplierName}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">承运商编码：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.LogisticserCode}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">承运商名称：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.LogisticserName}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">承运商产品：</el-col>
								<el-col :span="5">
                                    <span>{{dataSource.MemberProductName}}</span>
								</el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">总数量：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <span>{{dataSource.TotalNumber}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">总箱数：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <span>{{dataSource.TotalBoxNumber}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">总金额：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <span>{{dataSource.OrderAmt}}</span>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">总毛重(KG)：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <span>{{dataSource.TotalGrossWeight}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">总体积(m³)：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <span>{{dataSource.TotalCubage}}</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3" class="form-title">总净重(KG)：</el-col>
                                <el-col :span="5">
                                    <el-form-item>
                                        <span>{{dataSource.TotalNetWeight}}</span>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">预计发货时间：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.DeliverGoodsTime}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">预计到货时间：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.ScheduledProcessDate}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item v-if="dataSource.OrderStatus === 300">
                                <el-col :span="2" class="form-title">撤销时间：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.CancelTime}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">撤销人：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.CancelUserName}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">撤销原因：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.CancelRemarks}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item v-if="dataSource.OrderStatus === 300">
                                <el-col :span="2" class="form-title">撤销时间：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.SourceOrderNo}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item v-if="dataSource.InterceptStatus === 1">
                                <el-col :span="2" class="form-title">拦截标识：</el-col>
                                <el-col :span="5">
                                    <span>是</span>
                                </el-col>
                                <el-col :span="3" class="form-title">拦截人：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.InterceptUserName}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">拦截原因：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.InterceptRemarks}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item v-if="dataSource.InterceptStatus === 1">
                                <el-col :span="2" class="form-title">拦截时间：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.InterceptTime}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">
                                    订单备注：
                                </el-col>
                                <el-col :span="21">
                                    <span>{{dataSource.OrderMemo}}</span>
                                </el-col>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>
                    <el-collapse-item title="发货信息" name="senderinfo">
                        <el-form ref="senderForm" :model="dataSource">
                            <el-form-item>
                                <el-col :span="2" class="form-title">发货地址编码：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.SenderAddressCode}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">发货方：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.SenderCompany}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">联系人：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.DisplaySenderName}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">联系电话：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.DisplaySenderName}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">发货地址：</el-col>
                                <el-col :span="7">
                                    <span>{{dataSource.SenderProvince}}-{{dataSource.SenderCity}}-{{dataSource.SenderCounty}}</span>
                                </el-col>
                                <el-col :span="6">
                                    <span>{{dataSource.SenderAddress}}</span>
                                </el-col>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>
                    <el-collapse-item title="收货信息" name="receiverinfo">
                        <el-form ref="receiverForm" :model="dataSource">
                            <el-form-item>
                                <el-col :span="2" class="form-title">收货地址编码：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.ReceiverAddressCode}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">收货方：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.ReceiverCompany}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">联系人：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.DisplayReceiverName}}</span>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-col :span="2" class="form-title">联系电话：</el-col>
                                <el-col :span="5">
                                    <span>{{dataSource.ReceiverCompany}}</span>
                                </el-col>
                                <el-col :span="3" class="form-title">收货地址：</el-col>
                                <el-col :span="7">
                                    <span>{{dataSource.ProvinceName}}-{{dataSource.CityName}}-{{dataSource.CountyName}}</span>
                                </el-col>
                                <el-col :span="6">
                                    <span>{{dataSource.ReceiverAddress}}</span>
                                </el-col>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>
					<el-collapse-item title="商品信息" name="productinfo">
						<div class="sl-border">
						    <el-table v-loading="loadding" ref="ltsListTable" :data="OrderProductPage.Result" border  highlight-current-row>
						        <el-table-column type="index" width="50">
						        </el-table-column>
						        <el-table-column label="行号" min-width="100px">
						            <template slot-scope="scope">
						                <span>{{scope.row.OrderLineNo}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column label="商品编码" min-width="120px">
						            <template slot-scope="scope">
						                <span>{{scope.row.ProductNo}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column label="外部编码" min-width="110px">
						            <template slot-scope="scope">
						                <span>{{scope.row.OuterId}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column label="组合商品编码" min-width="110px">
						            <template slot-scope="scope">
						                <span>{{scope.row.ProductCombineNo}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column label="商品名称" min-width="160px">
						            <template slot-scope="scope">
						                <span>{{scope.row.ProductName}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column label="规格" min-width="100px">
						            <template slot-scope="scope">
						                <span>{{scope.row.Sku}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column label="商品条码" min-width="130px">
						            <template slot-scope="scope">
						                <span>{{scope.row.ProductBarCode}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column label="商品数量" min-width="110px">
						            <template slot-scope="scope">
						                <span>{{scope.row.OrderNumber}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column prop="OrderProductUnit" label="商品单位" min-width="130px">
						            <template slot-scope="scope">
						                <span>{{scope.row.OrderProductUnit}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column prop="InventoryType" label="库存类型" min-width="130px">
						            <template slot-scope="scope">
						                <span>{{scope.row.InventoryType}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column label="商品单价" min-width="110px">
						            <template slot-scope="scope">
						                <span>{{scope.row.ProductPrice}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column label="生产日期" min-width="110px" v-if="proDateDisplay">
						            <template slot-scope="scope">
						                <span>{{scope.row.ProductDate}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column label="生产批次号" min-width="110px" v-if="proBatchNoDisplay">
						            <template slot-scope="scope">
						                <span>{{scope.row.ProductBatchNo}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column label="序列号" min-width="110px">
						            <template slot-scope="scope">
						                <span>{{scope.row.ProductSN}}</span>
						            </template>
						        </el-table-column>
						        <!--批属性列自动生成-index从3开始，1,2使用的生产日期和批次号-->
						        <el-table-column :label="col.LotName?col.LotName:col.DefaultLotName"
						                         v-for="(col,index) in lotManages" v-if="col.IsDisplay" :key="index"
						                         min-width="140px">
						            <template slot-scope="scope">
						                <span v-if="config.isCanEdit">
						                    <el-input size="mini" v-if="col.LotType==2" v-model="scope.row['LotAttr'+(index+3)]"
						                              type="number" oninput="this.value=this.value.replace(/[^0-9]+/,'');"
						                              :placeholder="col.DefaultLotName">
						                    </el-input>
						                    <el-input size="mini" v-else-if="col.LotType==3"
						                              v-model="scope.row['LotAttr'+(index+3)]" type="number"
						                              oninput="this.value=this.value.replace(/[^0-9.]+/,'');"
						                              :placeholder="col.DefaultLotName">
						                    </el-input>
						                    <el-date-picker v-else-if="col.LotType==4" v-model="scope.row['LotAttr'+(index+3)]"
						                                    type="date" value-format="yyyy-MM-dd" :placeholder="col.DefaultLotName">
						                    </el-date-picker>
						                    <el-select v-else-if="col.LotType==5" v-model="scope.row['LotAttr'+(index+3)]"
						                               style="width: 100%;" multiple filterable allow-create default-first-option
						                               :placeholder="col.DefaultLotName">
						                        <el-option v-for="item in col.SelectList" :key="item" :label="item"
						                                   :value="item">
						                        </el-option>
						                    </el-select>
						                    <el-input size="mini" v-else v-model="scope.row['LotAttr'+(index+3)]"
						                              :placeholder="col.DefaultLotName">
						                    </el-input>
						                </span>
						                <span v-else>{{scope.row['LotAttr'+(index+3)]}}</span>
						            </template>
						        </el-table-column>
						        <el-table-column label="商品备注" min-width="150px">
						            <template slot-scope="scope">
						                <span>{{scope.row.ProductMemo}}</span>
						            </template>
						        </el-table-column>
						    </el-table>
						    <div style="float: left;margin-top: 5px;">
						        商品共计：{{productNum}}件；总毛重：{{productGrossWeight}}kg；总净重：{{productNetWeight}}kg；总金额：{{productAmount}}元；总体积：{{productVolume}}cm³
						    </div>
						    <div class="page">
						        <el-pagination @size-change="productPageSizeChange"
						            @current-change="productCurrentChange" :current-page="OrderProductPage.PageIndex"
						            :page-sizes="[10, 30, 50, 100]" :page-size="OrderProductPage.PageSize"
						            layout="total, sizes, prev, pager, next, jumper"
						            :total="OrderProductPage.TotalCount">
						        </el-pagination>
						        <el-button v-if="!config.isCanEdit" type="text" class="btn-refurbish" @click="onProductRefresh">
						            刷新
						        </el-button>
						    </div>
						</div>
					</el-collapse-item>
                </el-collapse>
            </el-tab-pane>
            <el-tab-pane label="日志信息" name="tabsOrderLogs" v-if="!config.isAdd">
                <el-table :data="dataSource.OrderLogs" border highlight-current-row>
                    <el-table-column prop="DisplayCreatedTime" label="创建日期">
                        <template slot-scope="scope">
                            <span v-model.trim="scope.row.CreatedTime">{{scope.row.CreatedTime}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OperateDesc" label="操作描述">
                        <template slot-scope="scope">
                            <span v-model.trim="scope.row.OperateDesc">{{scope.row.OperateDesc}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OperatorName" label="操作人">
                        <template slot-scope="scope">
                            <span v-model.trim="scope.row.OperateUserName">{{scope.row.OperateUserName}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <el-tabs type="border-card" v-model.trim="tab2activeName" v-if="false">
            <el-tab-pane label="商品明细" name="tabOrderProduct">
                <div class="sl-border">
                    <el-table v-loading="loadding" ref="ltsListTable" :data="OrderProductPage.Result" border  highlight-current-row>
                        <el-table-column type="index" width="50">
                        </el-table-column>
                        <el-table-column label="商品编码" min-width="120px">
                            <template slot-scope="scope">
                                <span>{{scope.row.ProductNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="外部编码" min-width="110px">
                            <template slot-scope="scope">
                                <span>{{scope.row.OuterID}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="组合商品编码" min-width="110px">
                            <template slot-scope="scope">
                                <span>{{scope.row.ProductCombineNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品名称" min-width="160px">
                            <template slot-scope="scope">
                                <span>{{scope.row.ProductName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="规格" min-width="100px">
                            <template slot-scope="scope">
                                <span>{{scope.row.SKU}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品条码" min-width="130px">
                            <template slot-scope="scope">
                                <span>{{scope.row.ProductBarCode}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品数量" min-width="110px">
                            <template slot-scope="scope">
                                <span>{{scope.row.OrderNumber}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="OrderProductUnit" label="商品单位" min-width="130px">
                            <template slot-scope="scope">
                                <span>{{scope.row.OrderProductUnit}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="InventoryType" label="库存类型" min-width="130px">
                            <template slot-scope="scope">
                                <span>{{scope.row.InventoryType}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品单价" min-width="110px">
                            <template slot-scope="scope">
                                <span>{{scope.row.ProductPrice}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="生产日期" min-width="110px" v-if="proDateDisplay">
                            <template slot-scope="scope">
                                <span>{{scope.row.ProductDate}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="生产批次号" min-width="110px" v-if="proBatchNoDisplay">
                            <template slot-scope="scope">
                                <span>{{scope.row.ProductBatchNo}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="序列号" min-width="110px">
                            <template slot-scope="scope">
                                <span>{{scope.row.ProductSN}}</span>
                            </template>
                        </el-table-column>
                        <!--批属性列自动生成-index从3开始，1,2使用的生产日期和批次号-->
                        <el-table-column :label="col.LotName?col.LotName:col.DefaultLotName"
                                         v-for="(col,index) in lotManages" v-if="col.IsDisplay" :key="index"
                                         min-width="140px">
                            <template slot-scope="scope">
                                <span v-if="config.isCanEdit">
                                    <el-input size="mini" v-if="col.LotType==2" v-model="scope.row['LotAttr'+(index+3)]"
                                              type="number" oninput="this.value=this.value.replace(/[^0-9]+/,'');"
                                              :placeholder="col.DefaultLotName">
                                    </el-input>
                                    <el-input size="mini" v-else-if="col.LotType==3"
                                              v-model="scope.row['LotAttr'+(index+3)]" type="number"
                                              oninput="this.value=this.value.replace(/[^0-9.]+/,'');"
                                              :placeholder="col.DefaultLotName">
                                    </el-input>
                                    <el-date-picker v-else-if="col.LotType==4" v-model="scope.row['LotAttr'+(index+3)]"
                                                    type="date" value-format="yyyy-MM-dd" :placeholder="col.DefaultLotName">
                                    </el-date-picker>
                                    <el-select v-else-if="col.LotType==5" v-model="scope.row['LotAttr'+(index+3)]"
                                               style="width: 100%;" multiple filterable allow-create default-first-option
                                               :placeholder="col.DefaultLotName">
                                        <el-option v-for="item in col.SelectList" :key="item" :label="item"
                                                   :value="item">
                                        </el-option>
                                    </el-select>
                                    <el-input size="mini" v-else v-model="scope.row['LotAttr'+(index+3)]"
                                              :placeholder="col.DefaultLotName">
                                    </el-input>
                                </span>
                                <span v-else>{{scope.row['LotAttr'+(index+3)]}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品备注" min-width="150px">
                            <template slot-scope="scope">
                                <span>{{scope.row.ProductMemo}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="float: left;margin-top: 5px;">
                        商品共计：{{productNum}}件；总毛重：{{productGrossWeight}}kg；总净重：{{productNetWeight}}kg；总金额：{{productAmount}}元；总体积：{{productVolume}}cm³
                    </div>
                    <div class="page">
                        <el-pagination @size-change="productPageSizeChange"
                            @current-change="productCurrentChange" :current-page="OrderProductPage.PageIndex"
                            :page-sizes="[10, 30, 50, 100]" :page-size="OrderProductPage.PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="OrderProductPage.TotalCount">
                        </el-pagination>
                        <el-button v-if="!config.isCanEdit" type="text" class="btn-refurbish" @click="onProductRefresh">
                            刷新
                        </el-button>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                activeGLName: 'first',
                collapseItem: ['basicinfo','senderinfo','receiverinfo','productinfo'],
                productIndex: 0,
                isSelectShop: false,
                ExternalSystemNames: [],
                QimenOrderTypes: [{
                    value: 100,
                    label: '入库单'
                }, {
                    value: 400,
                    label: '退货入库'
                }
                ],
                lotManages: [],//批属性管理数据
                proDateDisplay: false,
                proBatchNoDisplay: false,
                currentCarrierSelectedRow: {},
				currentCarrierProductSelectRow: {},
                currentWarehouseSelectedRow: {},
                currentProductSelectedRows: {},
                currentReceiverSelectedRow: {},
                activeName: 'tabbasic',
                tab2activeName: 'tabOrderProduct',
                locationFrom: 1,
                updateStatus: false,
                productNum: 0,
                productGrossWeight: 0,
                productNetWeight: 0,
                productAmount: 0,
                productVolume: 0,
                pickerDisable: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                },
                loadding: false,
                multipleSelection: [],
                initDataSource: {},
                dataSource:{
                    ReceiverSource:{
                        ProvinceName: '',
                        ProvinceID: '',
                        CityName: '',
                        CityID: '',
                        CountyName: '',
                        CountyID: '',
                    },
                    SenderSource:{
                        ProvinceName: '',
                        ProvinceID: '',
                        CityName: '',
                        CityID: '',
                        CountyName: '',
                        CountyID: '',
                    },
                    OrderExtend:{},
                },
                OrderProductPage:{PageIndex:1,PageSize:10,Result:[],OrderId:null},
                carrierProductConfig: {
					isDetailDisplay: true,
					MemberId: 0,
					MemberCode: ''
				},
                carrierProductOptions: {
					visible: false,
					size: 'small'
				},
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
        },
        mounted: function () {
            this.getLotManage();
        },
        computed: {
            OrderStatus() {
                return this.dataSource.OrderStatus;
            },
            OrderProductList() {
                if (this.OrderProductPage) {
                    return this.OrderProductPage.Result;
                }
                return new Array();
            }
        },
        watch: {
            'config.dataSourceKey':{
                handler(curVal, oldVal) {
                    if(curVal&&curVal>0){
                        if(curVal!=oldVal) this.initial(curVal); //初始化
                    }else{
                        //this.add(); //新增初始化
                    }
                },
                deep: true
            }
        },
        methods: {
            handleGLClick(tab, event) {
				if (tab.name == 'first') {
				} else if(tab.name == 'second')
				{
				}
				else if (tab.name == 'third') {
				} else if (tab.name == 'fourth') {
				}else if(tab.name == 'fifth')
				{
				}
			},
            //商品分页
            onProductRefresh() {
                this.productSearchPage(this.OrderProductPage);
            },
            productPageSizeChange(val) {
                this.OrderProductPage.PageSize = val;
                this.productSearchPage(this.OrderProductPage);
            },
            productCurrentChange(val) {
                this.OrderProductPage.PageIndex = val;
                this.productSearchPage(this.OrderProductPage);
            },
            productSearchPage(search) {
                var _this=this;
                var pageSearch = {
                    PageIndex: search.PageIndex,
                    PageSize: search.PageSize,
                    IsNoPage: this.config.isCanEdit,
                    ViewStatus: this.config.OrderDisplayTag,
                    Params: {
                        OrderId: search.Id
                    },
                };
                this.$ajax.query("omsapi/order/getorderproductlist", "post", pageSearch, (result) => {
                          _this.OrderProductPage.Result = result.Result;
                          _this.OrderProductPage.TotalCount = result.TotalCount;
                });
            },
            //重置表单
            resetSOForm: function () {
                this.Utils.clearForm(this.dataSource);
            },
            back: function () {
                this.config.isDetailDisplay = false;
            },
            initial(id) {
                var _this = this;
                this.$ajax.send("omsapi/dispatch/get", "get", { id: id }, (data) => {
                    _this.config.isAdd = false;
                    _this.config.isNormal = true;
                    _this.config.isReturn = false;
                    _this.resetSOForm();
                    _this.dataSource = data.Result;
                    // _this.$nextTick(function () {
                    // });
                });
                //商品明细分页-初始化
                // _this.OrderProductPage = {
                //         PageIndex:1,PageSize:10,Result:[]
                //     };
                _this.OrderProductPage.Id=id;
                _this.productSearchPage(_this.OrderProductPage);
                //初始化字段
                _this.dataSource.ReceiverSource = {};
                _this.dataSource.SenderSource = {};
                if (_this.dataSource.OrderStatus == 100) {
                    _this.config.isCanEdit = true;
                }
                else {
                    _this.config.isCanEdit = false;
                }
            },
            //批属性管理数据
            getLotManage() {
                if(!this.dataSource.CustomerCode){
                    return;
                }
                this.$ajax.send("omsapi/stockLotManage/findList?customerCode="+this.dataSource.CustomerCode, "get", {}, (data) => {
                    for (let index = 0; index < data.Result.length; index++) {
                        var lot = data.Result[index];
                        if (lot.DefaultLotProp == 'ProductDate' && lot.IsDisplay) {
                            this.proDateDisplay = true;
                        }
                        if (lot.DefaultLotProp == 'ProductBatchNo' && lot.IsDisplay) {
                            this.proBatchNoDisplay = true;
                        }
                        if (lot.Sort > 2) {//排除默认的四个属性和批属性1、2
                            this.lotManages.push(lot);
                        }
                    }
                });
            }
        },
        components: {
        }
    }
</script>
<style>
  .tab-container .el-col{min-height:1px;}
</style>